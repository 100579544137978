
.pos-right{
position: relative;
left: 30px;
}
.label-custom{
text-align: left;
font-family: Arial, Helvetica, sans-serif;
color:#000000e0;
font-size:18px;
font-weight: bold;
}
.label-span{
text-align: left;
font-family: Arial, Helvetica, sans-serif;
color:#000000e0;
font-size:18px;
}

.label-blank{
text-align: left;
font-family: Arial, serif;
color: white;
font-size: 80%;
}

.titletext{
text-align: center;
font-family: Arial, serif;
color: black;
font-size: 120%;
}

.brandname{
vertical-align: center;
}
.navbar{
padding:0;
}
fieldset.scheduler-border {
border: 1px groove #ddd !important;
padding: 0 1.4em 1.4em 1.4em !important;
margin: 0 0 1.5em 0 !important;
-webkit-box-shadow:  0px 0px 0px 0px #000;
box-shadow:  0px 0px 0px 0px #000;
}

legend.scheduler-border {
font-size: 1.2em !important;
font-weight: bold !important;
text-align: left !important;
width:auto;
padding:0 10px;
border-bottom:none;
}
.btnsearch{
margin-top: 32px;
}
/* Jyoti css starts here*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
input[type=number] {
-moz-appearance: textfield;
}
[className*="col-md-"] {
width: 100%;
}
@media screen and (max-width: 480px) {
    .image2{
        display: none;
        }
  }
@media only screen and (min-width : 768px) and (max-width : 1024px) {
    .text2 {
        font-size: 14px;
    }
    .textActive{
        font-size: 14px;  
    }
 }
/* @media only screen and (min-width: 600px) {
.col-md-1 {width: 8.33%;}
.col-md-2 {width: 16.66%;}
.col-md-3 {width: 25%;}
.col-md-4 {width: 33.33%;}
.col-md-5 {width: 41.66%;}
.col-md-6 {width: 100%;}
.col-md-7 {width: 58.33%;}
.col-md-8 {width: 66.66%;}
.col-md-9 {width: 100%;}
.col-md-10 {width: 83.33%;}
.col-md-11 {width: 91.66%;}
.col-md-12 {width: 100%;}

} */
/* @media only screen and (min-width: 1024px) {
// For desktop: 
.col-md-1 {width: 8.33%;}
.col-md-2 {width: 16.66%;}
.col-md-3 {width: 35%;}
.col-md-4 {width: 33.33%;}
.col-md-5 {width: 41.66%;}
.col-md-6 {width: 50%;}
.col-md-7 {width: 58.33%;}
.col-md-8 {width: 66.66%;}
.col-md-9 {width: 65%;}
.col-md-10 {width: 83.33%;}
.col-md-11 {width: 91.66%;}
.col-md-12 {width: 100%;}
} */
/* @media only screen and (min-width: 768px) {
// For desktop:
.col-md-1 {width: 33.33%;}
.col-md-2 {width: 16.66%;}
.col-md-3 {width: 100%;}
.col-md-4 {width: 100%;}
.col-md-5 {width: 100%;}
.col-md-6 {width: 100%;}
.col-md-7 {width: 58.33%;}
.col-md-8 {width: 66.66%;}
.col-md-9 {width: 100%;}
.col-md-10 {width: 83.33%;}
.col-md-11 {width: 91.66%;}
.col-md-12 {width: 100%;}
} */
.logo{
color:White;
background-color: rgb(50, 138, 209);
text-align: center;
}
#textfontsignin{
color: rgb(50, 138, 209);

}
.textblue{
color: rgb(50, 138, 209);

}
.brdcolor input{
border-color: rgb(50, 138, 209);
}
.brdcolor select{
border-color: rgb(50, 138, 209);
}
.brdcolor textarea{
border-color: rgb(50, 138, 209);
}
.brdcolor input[type=radio]{
border-color: rgb(50, 138, 209);
}
.errormsg{
color:red;
font-size: 13px;
}
.wlcmcolor{
color:gray;
font-size: 20px;
}
.htext{
font-size: 15px;
}
.heading{
font-size: 25px;
font-family: sans-serif;
font-weight: bold;
}
.txtstyle{
font-size: 15px;	
}
.headingtext{
font-size: 25px;
font-family: sans-serif;
font-weight: bold;
}
.box {
display: flex;
align-items: center;
justify-content: center;
}
.addevent{
height: 200px;
width: 300px;
padding:1px 5px;
box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2);
transition: 0.3s;
border:solid whitesmoke;
border-radius: 5px;	
}
.addevent:hover {
box-shadow: 0 8px 18px 0 rgba(0,0,0,0.2);
}
.table tr{
cursor: pointer;

}
table.tablepointer tr:hover{
	cursor:auto;
}

table tr span { 
opacity:0;
float:right; 
position:relative;
}
table tr:hover span {
opacity:1;
float:right;
position:relative;
}
.notestyle{
background-color: #ddd;
font-size: small;
border: none;
color: black;
padding: 8px 20px;
text-align: center;
text-decoration: none;
display: inline-block;
margin: 10px 10px;
cursor: pointer;
border-radius: 0px 45px 45px 0px;
}
.boxstyle{
margin-left: 2%;
}
.eventalign{
text-align: left;
}
.dollar{
position: relative;
border: 0px inset #ccc;
}
.dollar input{
padding-left:15px;
border-width: 0px  0px 1px 0px;
border-color: rgb(143, 141, 141);
text-indent: 15px;
text-align: right;
width: 170px;
}
.dollar:before {
position: absolute;
content:"$";
text-align: center;
left:5px;
}
.budgettotal{
text-align:right;
}
.dollartotal{
width: 170px;
text-align:right;	
}
.dollarroiinput{
width: 150px;
text-align:right;	
}
.thead{
background-color: rgb(231, 227, 227);
font-size:15px ;
font-weight: bold;

}
.pluscolor{
color:rgb(16, 135, 253);
font-size:20px ;
text-align: center;

}

.borderstyle input{
border-style: solid;
border-width: 0px  0px 2px 0px;
border-color: rgb(143, 141, 141);
text-align: right;
width: 150px;

}
.borderstyleinvisible {
width: 150px;
}
.invisiblefollow input{
border: solid;
border-color: transparent;
width: 250px;
padding : 0px;
margin: 0px;
}
.bobjective input{
border-style: solid;
border-width:1px;
border-color: rgb(143, 141, 141);
width: 250px;	
}
.borderstyleinvisible input{
border: none;
border-color: transparent;
}
.invisibleborder{
border: none;
border-color: transparent;
}
.colorborder{
border-style: solid;
border-width:1px;
border-color:rgb(50, 138, 209);
}
.deletebox span{
opacity: 0;
}
.deletebox:hover span{
opacity: 1;
}
.objboxstyle{
border: solid;
border-width: 1px;
border-color:rgb(50, 138, 209);
margin-bottom: 10px; 
}
.btnstyleActive{
border: none;
background-color:lightgrey;
color:#5F6368;
text-align: left;
padding:3px;
text-decoration: none;
font-size: 15px;
cursor: pointer;
}
.btnstyle{
border: none;
background-color: none;
color:#5F6368;
padding:5px;
text-align: left;
text-decoration: none;
font-size: 15px;
border-radius: 0px 45px 45px 0px;
cursor: pointer;
}		
.btnstyle:hover {
border: none;	
border-color: none;
color:rgb(16, 135, 253);
background-color:rgb(240, 236, 236);
} 
.btnstyle:focus{
background: rgb(67, 88, 155);
}
.circleBtn {
width: 70px; 
height: 70px; 
padding: 7px 10.7px; 
border-radius: 25px; 
font-size: 12px; 
text-align: center; 
background-color:rgb(200, 202, 204);
color: rgb(15, 13, 13);
text-decoration: none;
margin: 10px;

}
/* Mark the active step: */
.circleBtnActive {
width: 70px; 
height: 70px; 
padding: 7px 10.7px; 
border-radius: 25px; 
font-size: 12px; 
text-align: center;  
background-color:rgb(16, 135, 253);
border:none;
color:white;
text-decoration: none;
margin: 10px;

}
.textActive{
color:rgb(16, 135, 253);
text-align: left;
text-decoration: none;
cursor: pointer;
font-size: 20px;	
}
.text2{
font-size: 20px;
}
.barcodebox{
    height: 70%;
    width: 100%;
    display: block;
    padding: 3px;
}

/* Mark the steps that are finished and valid: */

.sucess
{
margin-top:80px;
padding-left: 70px;
padding-right: 70px;
color:rgb(50, 138, 209);
height: 5px;
text-align: center;
font-size: 25px;

}
.btnNormal{
background-color: none;
}

.red-border {
border-color:red !important;
}



/*Jyoti css ends */
/*sudha css start here */
.star{
color:red;

}
.displaybtn{
	display: block;
}
.hidebtn{
	display: none;
}
.text{
text-align: center;
}	
ul{
text-align: center;
}
.listitem1{
margin-right:50px;
}
.listitem3{
margin-left: 50px;
}
.image1{
width:70px;
height:70px;
}
.image2{
width:700px;
height:70px;
}
.listitem1{
margin-right: 100px;
}
.listitem3{
margin-left:100px ;
}

.rounded {
border-radius: 1rem
}

.nav-pills .nav-link {
color: #555
}

.nav-pills .nav-link.active {
color: white
}
.temp{
margin-bottom: -1px;
font-size: 15px;
font-weight:normal;
text-align: center;
}

.navbarhight {
min-height: 70px;
}
.redlink{
color:red;
}
.greenlink{
color: green;
}
.password{
font-size: 15px;
}
.btnclass{
font-size: 20px;
}
.temp{
border: none;
width:50%;
text-align: center;
font-size: 15px;

}
.nav-item{
background-color: lightgray;

}
li {
border-right: 1px solid white;

}

.templateActive:hover{
background-color:rgb(212, 228, 235);
border-top: 3px solid skyblue;
cursor: pointer;
}
.userActive{
background-color:rgb(212, 228, 235); 
border-top: 3px solid skyblue;

}
.userdetails{
background-color: lightgray;

}
.custom-checkboxes{
	transform:scale(0.8);

}
input[type="checkbox"]:focus{
	
	box-shadow:none;
}
.userdetails:hover{
background-color:rgb(212, 228, 235); 
border-top: 3px solid skyblue;
cursor: pointer;

}
.transaction:hover{
background-color:rgb(212, 228, 235);
border-top: 3px solid skyblue;
cursor: pointer;

}
.transaction{
background-color: lightgray; ;
}
.settingActive{
background-color:rgb(212, 228, 235); 
border-top: 3px solid skyblue;
}
.logoActive{
background-color:rgb(212, 228, 235); 
border-top: 3px solid skyblue;
}
.transActive{
background-color:rgb(212, 228, 235); 
border-top: 3px solid skyblue;
}
.settings:hover{
background-color:rgb(212, 228, 235);
border-top: 3px solid skyblue;
cursor: pointer;

}
.catalogActive{
background-color:rgb(212, 228, 235); 
border-top: 3px solid skyblue;

}
.settings{
background-color: lightgray; ;
}
.templates{
background-color: lightgray; 
}
.uploadcatalog{
	background-color: lightgray; 
}
.uploadcatalog:hover{
background-color:rgb(212, 228, 235);
border-top: 3px solid skyblue;
cursor: pointer;
}

.templates:hover{
background-color:rgb(212, 228, 235);
border-top: 3px solid skyblue;
cursor: pointer;

}
.uploadlogo:hover{
background-color:rgb(212, 228, 235);
border-top: 3px solid skyblue;
cursor: pointer;

}
.uploadlogo:hover{
background-color: lightgray; ;
}
.transActive{
background-color:rgb(212, 228, 235); 
border-top: 3px solid skyblue;
}
.btnload{
margin-left: -100px;
}
.settingheader{
color:#007bff;
}
.fa-icon-color{
color:red;
}
.sample67 {

position: relative;
}

.append1{
font-weight: bold;
justify-content: center;
margin-top: -50px;
margin-left: 10px;
position: absolute;
}

.labeltext{

text-align: center;
margin-bottom: -3px;


}
.inputbox{
width:50%;
border: none;
text-align: center;
font-size: 17px;
margin-bottom:-8px ;
}
.singlelabel .barcode{
text-align: center;
display: none;

}
.printlabel{
display: block;
}
.labels{
display: none;
}
.labelname{
display:none ;
}
.csvfile{
color:black;
text-decoration: none;
}
.fafilecolor{
color:blue;
font-size: x-large;
text-decoration: none;
}
.custom-check-box{
	width:20px;
	height:20px;
}
.namebtn{
width:130px;
text-align: left;
background-color: lightgray;
border: 1px solid gray;

}
.billform{
width:100%;
border: 1px solid lightgray;
background-color: lightsteelblue;
cursor: pointer;

}
.totalAmount{
text-align: right;
}
.subtotal{
text-align: right;
}
.display{
visibility: visible;
}
.nondisplay{
visibility: hidden;
}

.hidebarcodebtn{
	visibility: hidden;
}
.my-custom-scrollbar {
position:relative;
height: 300px;
overflow-y:auto;
overflow-x: hidden;
}

.table-wrapper-scroll-y {
display: block;
}


.nondisabled{
	visibility: hidden;
}
.savedisplay{
	visibility:hidden;
}
input[type=radio]{
	transform:scale(1.5);
  }
  
  .showbutton{
	  visibility: visible;
  }
.scrollbar {
position:relative;
height: 200px;
overflow-y:auto;
overflow-x: hidden;
}


.input-icons i {
position: absolute;
top: 50%;
right: 10px;
transform: translateY(-50%);
}

.input-icons {
position: relative;
width: 100%;
margin-bottom: 10px;
}

.icon {
padding: 10px;
color:rgb(15, 13, 13);
min-width: 30px;
text-align: center;
}
.checkboxpadding{
	padding: 0px;
}
.form-group.floating>label {
    bottom: 34px;
    left: 8px;
    position: relative;
    background-color: white;
    padding: 0px 5px 0px 5px;
    font-size: 1.1em;
    transition: 0.1s;
    pointer-events: none;
    font-weight: 500 !important;
    transform-origin: bottom left;
}

.form-control.floating:focus~label{
    transform: translate(1px,-85%) scale(0.80);
    opacity: 1;
    color: #005ebf;
}

.form-control.floating:valid~label{
    transform-origin: bottom left;
    transform: translate(1px,-85%) scale(0.80);
    opacity: 1;
}
.pendingbill{
    color: rgb(23, 52, 219);
    margin-top: 2rem;
    font-size: 1.0rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

  .menus{
      margin-right: 12.8px;
  }
  .row{
      margin-top: 9.5px;
  }
  #page-container {
    position: relative;
    min-height: 100vh;
  }
  
  #content-wrap {
    padding-bottom: 2.5rem;    /* Footer height */
  }
  
  #footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;            /* Footer height */
  }

/*--- Print edit ---*/
.bcode{
  transform: rotateZ(-90deg) scale(0.75);
  width: fit-content;
}
.barcode-container{
  position: absolute;
  width: 1in;
  height: 4in;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.grid-3-col{
  position: absolute;
  display: grid;
  top: 1in;
  left: 1in;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1in
}
